import * as React from "react"
import { Link } from "gatsby"

import MainLayout from "../hoc/main-layout";
import Hero from "../components/hero/hero";

import * as notFoundS from './404.module.scss';

const NotFoundPage = () => {
  return (
    <MainLayout>

      <Hero />

      <section className={notFoundS.content}>

        <h2>Nie znaleziono tej strony</h2>

        <a href="./" title="Strona główna" >Wróć na stronę główną</a>

      </section>

    </MainLayout>
  )
}

export default NotFoundPage
